module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rhode Island Center for Employee Ownership","short_name":"Rhode Island CEO","start_url":"/","background_color":"#2a2a2a","theme_color":"#10798F","display":"minimal-ui","icon":"src/images/logo-compact.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"30d15ed14c66502d9ab1172c17a1b0ff"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
